<template>
  <div id="lesson" class="select-main">
    <div class="div-main">
      <loading :loading="loading" :text="loadingText"></loading>
      <el-form id="formSearch" ref="form" label-width="100px" style="border-bottom: 1px solid #f6f6f6; margin-bottom: 5px; margin-top: 5px;">
        <el-form-item label="资料ID" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="number"  v-model="id" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="资料名称" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="text"  v-model="name" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="所属分类" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="categoryId" placeholder="请选择">
            <el-option
                v-for="category in categories"
                :key="category.id"
                :label="category.name"
                :value="category.id">
            </el-option>
          </el-select>
        </el-form-item>

        <div style="display:inline-block;margin-left: 60px">
          <el-button type="primary" @click="getData">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </div>
      </el-form>
      <div id="divList">
        <div class="opt">
          <el-button type="primary" size="small" @click="handleClickAdd">添加</el-button>
        </div>
        <el-table id="tblList" border :data="resources" style="width: 100%" v-show="tableShow" header-row-class-name="table-header">
          <el-table-column prop="id" label="ID" align="center" width="100"></el-table-column>
          <el-table-column prop="name" label="资料名称" align="left"></el-table-column>
          <el-table-column prop="url" label="下载地址" align="left"></el-table-column>
          <el-table-column prop="createdOn" label="创建日期" align="center" width="200"></el-table-column>
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button @click="handleClickEdit(scope.row)" type="text" size="small">编辑</el-button>
              <el-button @click="handleClickDel(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page="page" :size="size" @pageChange="handleCurrentChange" @sizeChange="handleSizeChange"></pagination>
      </div>
    </div>
    <edit-dialog ref="editDialog"></edit-dialog>
  </div>
</template>

<script>
import {
  UrlEnum,
  POP_WIDTH_MIDDLE,
  POP_HEIGHT_MIDDLE,
  TipEnum,
  getLocalProjectId,
} from "../../../public/js/common-vue";
import Pagination from "@/components/Pagination";
import EditDialog from "@/components/EditDialog";
import Loading from "@/components/Loading";

export default {
  name: "AdList",
  data() {
    return {
      loading: false,
      loadingText:"加载中",
      tableShow:false,
      id:'',
      name:'',
      categoryId:'',
      total:0,
      page:1,
      size:20,
      categories:[],
      resources:[]
    }
  },
  components:{Loading, Pagination,EditDialog},
  methods:{
    initCategories(){
      this.$http({
        method: 'GET',
        url: UrlEnum.RESOURCE_CATEGORIES + '?page=1&size=100',
        data: '',
      })
          .then((res) => {
            this.categories = res.data.list;
          })
          .catch((res) => {
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
    },
    handleClickAdd(){
      const url = 'add.html?' + new Date();
      this.$refs.editDialog.init('edit',POP_WIDTH_MIDDLE,POP_HEIGHT_MIDDLE,'添加资料',url);
    },
    handleClickEdit(row){
      this.$refs.editDialog.init('edit',POP_WIDTH_MIDDLE,POP_HEIGHT_MIDDLE,'编辑资料','edit.html?id='+row.id);
    },
    handleClickDel(row){
      this.$confirm('确定删除此信息？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del(row.id);
      }).catch(() => {
        console.log('catch')
      });
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getData();
    },
    handleSizeChange(size) {
      this.size = size;
      this.getData();
    },
    makeUrl() {
      let url = UrlEnum.RESOURCE;
      url += "?page="+this.page+"&size="+this.size;
      if(this.id != ''){
        url += "&id="+this.id;
      }
      if(this.name != ''){
        url += "&name="+this.name;
      }
      if(this.categoryId != ''){
        url += "&categoryId=" + this.categoryId;
      }

      url += "&projectId="+getLocalProjectId()
      return url;
    },
    getData() {
      this.loading=true;
      const url = this.makeUrl();
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading=false;
        this.total = res.data.total;
        this.resources = res.data.list;
        this.tableShow=true;
       })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
        this.tableShow=true;
      })
    },
    reset(){
      this.page=1;
      this.id='';
      this.name='';
      this.categoryId='';
      this.getData();
    },
    del(id){
      this.loading = true;
      const url = UrlEnum.RESOURCE + '/'+id;
      this.$http({
        method: "delete",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading=false;
        this.showMsgSuccess(TipEnum.DELETE_SUCCESS);
        this.getData();
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      })
    },
  },
  mounted() {
    this.getData();
    this.initCategories();
    const that = this;
    window.addEventListener('message', function (e) {
      if(e.data.key == "refresh"){
        that.$refs.editDialog.hide();
        that.getData();
      }
    }, false)
  }
}
</script>

<style>
.export{
  display:none;
}
.div-main{
  background: white;
  padding:10px 0px;
  height: 100%;
  overflow: auto;
  margin:15px;
}
.select-main{
  height: 100%;
}
.table-header th {
  background-color: #f6f6f6 !important;
}
.el-table td {
  padding: 6px 0 !important;
}
#divList{
  margin:10px;
}
.opt{
  height: 40px;
}
</style>
